import { usePromo as getPromo, promoStart, promoEnd } from "../../utils/usePromo";
import {useAbTest as getAbTest}  from '../../utils/useAbTest'
import getIsLifemart from "../Lifemart/hooks/useIsLifemart";
import getIsMilitary from "../Military/hooks/useIsMilitary";

export const comparisonInfo = (hubblePricing) => {
  const isPromo = getPromo(promoStart, promoEnd)
  const isPaidShipping = getAbTest()
  const isLifemart = getIsLifemart()
  const isMilitary = getIsMilitary()

  return(
    {
      price: {
        classic: isPaidShipping ?
          `<p><strike>${!isLifemart && !isMilitary ? "$22.99/eye" : "$19.99/eye"}</strike> <b>$1*</b></p>` :
          `<p><strike>${!isLifemart && !isMilitary ? "$22.99/eye" : "$19.99/eye"}</strike> <b>$1</b></p>`,
        hydro: isPromo ?
          `<p><strike>$${hubblePricing.hydro.subPrice}/eye</strike> <b>$${hubblePricing.hydro.firstPrice}/eye</b></p> <p class='limited-time-text'>Limited Time Only</p>` :
          isPaidShipping ?
          `<p><strike>$${hubblePricing.hydro.subPrice}/eye</strike> <b>$${hubblePricing.hydro.firstPrice}/eye*</b></p> ` :
          `<p><strike>$${hubblePricing.hydro.subPrice}/eye</strike> <b>$${hubblePricing.hydro.firstPrice}/eye</b></p>`,
        skyhy: isPromo ?
          `<p><strike>$${hubblePricing.skyhy.subPrice}/eye</strike> <b>$${hubblePricing.skyhy.firstPrice}/eye</b></p> <p class='limited-time-text'>Limited Time Only</p>` :
          isPaidShipping ?
          `<p><strike>$${hubblePricing.skyhy.subPrice}/eye</strike> <b>$${hubblePricing.skyhy.firstPrice}/eye*</b></p>` :
          `<p><strike>$${hubblePricing.skyhy.subPrice}/eye</strike> <b>$${hubblePricing.skyhy.firstPrice}/eye</b></p>`,
      },
      fda: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      personalized: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      eco: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      daily: {
        classic: true,
        hydro: true,
        skyhy: true,
      },
      natural: {
        classic: "",
        hydro: true,
        skyhy: true,
      },
      blink: {
        classic: "",
        hydro: true,
        skyhy: true,
      },
      world: {
        classic: "",
        hydro: true,
        skyhy: "",
      },
      touch: {
        classic: "",
        hydro: true,
        skyhy: "",
      },
      high_oxygen: {
        classic: "",
        hydro: "",
        skyhy: true,
      },
      flex_wear: {
        classic: "",
        hydro: "",
        skyhy: true,
      },
      top_choise: {
        classic: "",
        hydro: "",
        skyhy: true,
      },

    }
  )
}

export const labels = {
  price: "first order price",
  fda: "Fda-approved",
  personalized: "personalized delivery cadence",
  daily: {text: "daily disposables", sub: 'The safest type of contact lenses'},
  eco: {text: "Packaging made from recyclable material"},
  natural: {text: "Natural Eye Hydration", sub: "Material and shape are specially designed to retain moisture on the lens surface"},
  blink: {text: "Blink Comfort Design", sub: "Lens design minimizes discomfort when blinking"},
  world: {text: "World's slimmest lens packaging", sub: "Less plastic, less space, more convenient"},
  touch: {text: "Hygienic Touch Technology", sub: "Packaged so that your fingers never touch the inside of the lens"},
  high_oxygen: {text: "High Oxygen PERMEABILITY", sub: "Cutting-edge silicone hydrogel material allows oxygen to move through the lens for fresh eyes all day"},
  flex_wear: {text: "Soft flex wear", sub: "Flexible construction allows lenses to mold to the curve of your eye, fitting snugly and comfortably over the surface"},
  top_choise: {text: "Doctors’ top choice", sub: "7 out of 10 new prescriptions are for lenses made of SkyHy's material (silicone hydrogel)"},
}

export const contactLenses = (isClassicNewPrice, hubblePricing) => {
  return(
    [
      isClassicNewPrice
      ? {title: "Hubble Contact Lenses", feature: "affordable dailies", type: "classic", product: "Classic", price: "$1 Starter Pack", originalPrice: "$22.99/eye"}
      : {title: "Hubble Contact Lenses", feature: "affordable dailies", type: "classic", product: "Classic", price: "$1 Starter Pack", originalPrice: "$19.99/eye"},
      {title: "Hydro by Hubble Contact Lenses", feature: "naturally hydrating", type: "hydro", product: "Hydro", price: `$${hubblePricing.hydro.firstPrice}/eye`, originalPrice: `$${hubblePricing.hydro.subPrice}/eye`, percent_off: hubblePricing.hydro.percentOff, tagText:'popular choice', tagColor: 'purple'},
      {title: "SkyHy by Hubble Contact Lenses", feature: "most breathable", type: "skyhy", product: "Skyhy", price: `$${hubblePricing.skyhy.firstPrice}/eye`, originalPrice: `$${hubblePricing.skyhy.subPrice}/eye`, percent_off: hubblePricing.skyhy.percentOff, tagText:'premium pick', tagColor: 'orange'},
    ]
  )
}
